<template>
    <Toolbar>
        <v-toolbar-title>
            <div v-if="isLeadGenVariation" class="logoContainer">
                <Logo />
            </div>
            <router-link v-else to="/" class="logoContainer">
                <Logo />
            </router-link>
        </v-toolbar-title>
        <v-menu
            v-if="isDefaultDashboardOrFunnelVariation"
            open-on-hover
            bottom
            offset-y
            close-delay="200"
        >
            <template v-slot:activator="{ on }">
                <button
                    :class="{
                        navItem: true,
                        'nuxt-link-active': isAboutUsNavItemActive,
                    }"
                    aria-haspopup="true"
                    @mousedown.prevent
                    v-on="on"
                >
                    <span>{{ $t('NAVBAR_ABOUT_US') }}</span>
                    <SVGIcon
                        icon-name="cs_chevron-down"
                        class="navItem__icon"
                    />
                </button>
            </template>
            <div class="navItemMenu">
                <a href="/ueber-uns" class="navItemMenu__item">
                    {{ $t('NAVBAR_ABOUT_CARESHIP') }}
                </a>
                <a href="/senioren-ratgeber" class="navItemMenu__item">
                    {{ $t('NAVBAR_CARESHIP_MAGAZINE') }}</a
                >
                <a href="/faq" class="navItemMenu__item">
                    {{ $t('NAVBAR_FAQ_CUSTOMERS') }}
                </a>
                <router-link to="/partner" class="navItemMenu__item">
                    {{ $t('NAVBAR_PARTNERS') }}
                </router-link>
                <a href="/newsroom" class="navItemMenu__item">
                    {{ $t('NAVBAR_PRESS') }}
                </a>
                <a
                    href="https://mycareship.jobs.personio.de/"
                    class="navItemMenu__item"
                    >{{ $t('NAVBAR_CAREER') }}
                </a>
            </div>
        </v-menu>
        <v-menu
            v-if="isDefaultDashboardOrFunnelVariation"
            open-on-hover
            bottom
            offset-y
            close-delay="200"
        >
            <template v-slot:activator="{ on }">
                <button
                    class="navItem"
                    aria-haspopup="true"
                    @mousedown.prevent
                    v-on="on"
                >
                    <span>{{ $t('NAVBAR_CAREGIVERS') }}</span>
                    <SVGIcon
                        icon-name="cs_chevron-down"
                        class="navItem__icon"
                    />
                </button>
            </template>
            <div class="navItemMenu">
                <a href="/unsere-betreuer" class="navItemMenu__item">
                    {{ $t('NAVBAR_OUR_CAREGIVERS') }}
                </a>
                <a href="/hilfe" class="navItemMenu__item">
                    {{ $t('NAVBAR_FAQ_CAREGIVERS') }}
                </a>
                <a
                    href="https://my.careship.de/login"
                    class="navItemMenu__item"
                >
                    {{ $t('NAVBAR_MY_CARESHIP_LOGIN') }}
                </a>
            </div>
        </v-menu>
        <a
            v-if="isDefaultDashboardOrFunnelVariation"
            href="/angebot"
            class="navItem"
        >
            <span>{{ $t('NAVBAR_SERVICES') }}</span>
        </a>
        <router-link
            v-if="isDefaultDashboardOrFunnelVariation"
            to="/preise"
            class="navItem"
            @mousedown.native.prevent
        >
            <span>{{ $t('NAVBAR_PRICE') }}</span>
        </router-link>
        <v-spacer></v-spacer>
        <a
            v-if="isDashboardVariation"
            v-ripple
            href="/kunde/meine-alltagshelfer"
            class="navItemCta navItemCta--segmentedLeft"
        >
            {{ $t('NAVBAR_LOGGED_IN_AREA') }}
        </a>
        <v-menu
            v-if="isDashboardVariation"
            open-on-hover
            left
            offset-y
            close-delay="200"
        >
            <template v-slot:activator="{ on }">
                <button
                    v-ripple
                    class="navItemCta navItemCta--segmentedRight"
                    aria-haspopup="true"
                    v-on="on"
                    @mousedown.prevent
                >
                    <SVGIcon
                        icon-name="cs_chevron-down"
                        class="navItem__icon"
                    />
                </button>
            </template>
            <div class="navItemMenu">
                <a href="/kunde/meine-alltagshelfer" class="navItemMenu__item">
                    <SVGIcon icon-name="cs_users" class="navItemMenu__icon" />
                    <span>{{ $t('NAVBAR_MY_CAREGIVERS') }}</span>
                </a>
                <a href="/kunde/meine-buchungen" class="navItemMenu__item">
                    <SVGIcon
                        icon-name="cs_calendar"
                        class="navItemMenu__icon"
                    />
                    <span>{{ $t('NAVBAR_MY_BOOKINGS') }}</span>
                </a>
                <a href="/kunde/zahlungsmethode" class="navItemMenu__item">
                    <SVGIcon icon-name="cs_bank" class="navItemMenu__icon" />
                    <span>{{ $t('NAVBAR_MY_PAYMENT_METHOD') }}</span>
                </a>
                <a href="/kunde/meine-familie" class="navItemMenu__item">
                    <SVGIcon icon-name="cs_account" class="navItemMenu__icon" />
                    <span>{{ $t('NAVBAR_MY_PROFILE') }}</span>
                </a>
                <div class="navItemMenu__divider"></div>
                <a href="/kunde/logout" class="navItemMenu__item">
                    <SVGIcon icon-name="cs_log-out" class="navItemMenu__icon" />
                    <span>{{ $t('NAVBAR_LOGOUT') }}</span>
                </a>
            </div>
        </v-menu>
        <a
            v-if="
                isPhoneContactVariation ||
                    isPhoneContactBVariation ||
                    isLeadGenVariation
            "
            :href="`tel:${phoneNumber}`"
            :class="{
                navItem: true,
                'navItem--dividerRight': isPhoneContactVariation,
            }"
        >
            <SVGIcon icon-name="phone-call" class="phoneIcon" />
            <span>{{ phoneNumber }}</span>
        </a>
        <a
            v-if="isPhoneContactVariation"
            :href="applicationPortalLink"
            class="navItem"
        >
            <span>{{ $t('NAVBAR_BECOME_CAREGIVER') }}</span>
        </a>
        <a v-if="isDefaultVariation" href="/kunde/login" class="navItem">
            <span>{{ $t('NAVBAR_LOGIN') }}</span>
        </a>
        <a
            v-if="isDefaultVariation"
            :href="applicationPortalLink"
            class="navItem"
        >
            {{ $t('NAVBAR_BECOME_CAREGIVER') }}
        </a>
        <a
            v-if="isFunnelVariation"
            v-ripple
            :href="SRDraftUrl"
            class="navItemCta"
            >{{ $t('NAVBAR_CONTINUE_SEARCH') }}</a
        >
        <a
            v-if="
                isDefaultVariation ||
                    isPhoneContactVariation ||
                    isPhoneContactBVariation
            "
            id="button_header_booking-funnel"
            v-ripple
            :href="bookingPortalEntryLink"
            class="navItemCta"
            >{{ $t('NAVBAR_FIND_CAREGIVERS') }}
        </a>
    </Toolbar>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import Logo from './components/logo.vue'
import Toolbar from './components/toolbar.vue'

export default {
    name: 'Navigation',
    components: {
        SVGIcon,
        Logo,
        Toolbar,
    },
    props: {
        navVariation: {
            type: String,
            required: true,
        },
        SRDraftUrl: {
            type: String,
            required: true,
        },
        phoneNumber: {
            type: String,
            required: true,
        },
    },
    computed: {

        bookingPortalEntryLink(){
            const queryString = window.location.search;

            // Construct the new URL with the existing query string
            return `/kunde/anfrage/${queryString}`;
        },
        applicationPortalLink(){
            const queryString = window.location.search;

            // Construct the new URL with the existing query string
            return `/kunde/alltagshilfe/bewerbung/${queryString}`;
        },
        isDefaultVariation() {
            return this.navVariation === 'default'
        },
        isDashboardVariation() {
            return this.navVariation === 'dashboard'
        },
        isFunnelVariation() {
            return this.navVariation === 'funnel'
        },
        isPhoneContactVariation() {
            return this.navVariation === 'phone-contact'
        },
        isPhoneContactBVariation() {
            return this.navVariation === 'phone-contact-b'
        },
        isLeadGenVariation() {
            return this.navVariation === 'lead-gen'
        },
        isDefaultDashboardOrFunnelVariation() {
            return (
                this.isFunnelVariation ||
                this.isDashboardVariation ||
                this.isDefaultVariation
            )
        },
        isAboutUsNavItemActive() {
            return this.$route.path === '/partner'
        },
    },
}
</script>

<style lang="stylus" scoped>
$navItemTransition = 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
$navItemMarginRight = 4px

.logoContainer {
    display: flex;
    color: $cs-primary;
    margin-right: ($navItemMarginRight * 4);
}

.phoneIcon {
    height: 20px;
    width: 20px;
    margin-right: $cs-space;
}

.navItem {
    font-size: 16px;
    margin-right: $navItemMarginRight;
    padding: $cs-spaces.half $cs-space;
    border-radius: 28px;
    text-decoration: none;
    color: $cs-slate-400;
    font-weight: 500;
    transition: background-color $navItemTransition;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    letter-spacing: 0px;
    line-height: 24px;

    &:hover {
        background-color: $cs-slate-050;
    }

    &:focus {
        outline: none;
        background-color: $cs-slate-050;
    }

    &:last-child {
        margin-right: 0;
    }


    &--dividerRight {
        position: relative;
        margin-right: ($navItemMarginRight * 2);

        &::after {
            content: '';
            position: absolute;
            height: 100%;
            border-left: 1px solid $cs-slate-100;
            right: -($navItemMarginRight);
        }
    }
}

.navItem.nuxt-link-active {
    &::before {
        content: '';
        position: absolute;
        width: 'calc(100% - %s)' % $cs-spaces.double;
        height: 4px;
        background-color: $cs-primary;
        top: -15px;
        left: $cs-space;
        border-radius: 2px;
    }
}

.navItemCta {
    font-size: 16px;
    font-weight: 500;
    padding: $cs-spaces.half $cs-spaces.onehalf;
    background-color: $cs-primary;
    color: $cs-white;
    border-radius: 28px;
    text-decoration: none;
    transition: opacity $navItemTransition;
    letter-spacing: 0px;
    line-height: 24px;

    &:hover {
        opacity: 0.9;
    }

    &:focus {
        outline: none;
        opacity: 0.8;
    }

    &--segmentedLeft {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px solid $cs-white;
        padding-right: $cs-space;
        margin-right: 0;
    }

    &--segmentedRight {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding-left: 0;
        padding-right: 12px;
    }
}

.navItem__icon {
    height: 16px;
    width: 16px;
    margin-left: $cs-spaces.half;
    vertical-align: middle;
}

.navItemMenu {
    background-color: $cs-white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.navItemMenu__item {
    text-decoration: none;
    border-radius: 0;
    font-weight: normal;
    width: 100%;
    font-size: 16px;
    color: $cs-slate-400;
    display: flex;
    align-items: center;
    padding: $cs-spaces.half $cs-space;
    transition: background-color $navItemTransition;
    letter-spacing: 0;
    line-height: 24px;

    &.nuxt-link-active {
        background-color: #fff7e5;
        font-weight: 500;
    }

    &:hover {
        background-color: $cs-slate-050;
    }

    &:focus {
        outline: none;
        background-color: $cs-slate-050;
    }

    &:first-child {
        padding-top: $cs-spaces.half;
    }

    &:last-child {
        padding-bottom: $cs-spaces.half;
    }
}

.navItemMenu__icon {
    height: $cs-spaces.onehalf;
    width: $cs-spaces.onehalf;
    margin-right: $cs-space;
}

.navItemMenu__divider {
    border-bottom: 1px solid $cs-slate-100;
    width: 100%;
}

.v-menu__content {
  border-radius: $cs-border-radius;
  margin-top: 6px;
}
</style>
