<template>
    <SVGIcon icon-name="careship-logo" class="careshipLogo" />
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

export default {
    name: 'NavLogo',
    components: {
        SVGIcon,
    },
}
</script>

<style lang="stylus" scoped>
.careshipLogo {
    padding-top: $cs-spaces.half;
    padding-bottom: $cs-spaces.half;
    height: 65px;
    width: 135px;
}
</style>
