<template>
    <v-app>
        <div class="page page--header">
            <Navigation phone-contact />

            <main class="page__wrap">
                <nuxt />
            </main>

            <Footer />
        </div>
    </v-app>
</template>

<script>
import Navigation from '@/components/organisms/navigation/navigation.vue'
import Footer from '@/components/organisms/footer/footer.vue'

export default {
    name: 'DefaultLayout',

    components: {
        Navigation,
        Footer,
    },
}
</script>
