export default {
    methods: {
        trackEvent(params) {
            const { category, action, label, nonInteraction } = params

            dataLayer.push({
                event: 'MYCARESHIP',
                category: String(
                    category || 'user-page interactions',
                ).toLowerCase(),
                action: String(action || '').toLowerCase(),
                label: String(label || '').toLowerCase(),
                nonInteraction: nonInteraction || true,
            })
        },

        trackEventForUserActions(
            action,
            labelText,
            labelURL = '',
            nonInteraction = true,
        ) {
            this.trackEvent({
                action: `user clicked on ${action}`,
                label: labelURL ? `${labelText} - ${labelURL}` : labelText,
                nonInteraction,
            })
        },
    },
}
