<template>
    <div class="emailForm">
        <div
            v-if="header || subline"
            class="emailForm__information header__title"
        >
            <component :is="header" v-text="title" />

            <p v-if="subline" class="header__subline">
                {{ subline }}
            </p>
        </div>

        <v-form ref="form" lazy-validation @submit.prevent="submit">
            <p v-if="hint" class="emailForm__hint">
                {{ hint }}
            </p>

            <CSTextField
                data-test="emailForm_textField"
                single-line
                type="email"
                name="email"
                :placeholder="inputPlaceholder"
                :rules="[rules.required, rules.email]"
                :on-change="updateEmail"
                :class="{
                    'emailForm__input--approved': form.successfullySubmitted,
                }"
            >
                <template v-slot:iconAppend>
                    <v-fade-transition leave-absolute>
                        <SVGIcon
                            :icon-name="
                                form.successfullySubmitted ? 'check' : 'mail'
                            "
                            class="icon__email"
                        />
                    </v-fade-transition>
                </template>
            </CSTextField>

            <CSButton
                type="submit"
                data-test="emailForm_button"
                :text="buttonSubmit"
                class="emailForm__button"
                block
                :large="ctaLargeButton"
                :color="form.successfullySubmitted ? 'secondary' : 'primary'"
                :dark="form.successfullySubmitted"
                :loading="form.loading"
            />

            <v-slide-y-transition mode="out-in">
                <span
                    v-if="form.statusMessage"
                    :class="[
                        'emailForm__statusMessage',
                        {
                            'emailForm__statusMessage--error': !form.successfullySubmitted,
                        },
                    ]"
                >
                    {{ form.statusMessage }}
                </span>
            </v-slide-y-transition>

            <div v-if="disclaimer" class="emailForm__disclaimer">
                {{ disclaimer }}
            </div>
        </v-form>
    </div>
</template>

<script>
import CSButton from '@/components/atoms/button/CSButton.vue'
import CSTextField from '@/components/atoms/form/CSTextField.vue'
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

import formValidation from '@/mixins/validation'
import gtmTracking from '@/mixins/gtmTracking'

import { subscribeEmail } from '@/src/mailchimp'

export default {
    name: 'EmailForm',

    components: {
        CSButton,
        CSTextField,
        SVGIcon,
    },

    mixins: [gtmTracking, formValidation],

    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },

        subline: {
            type: String,
            required: false,
            default: '',
        },

        hint: {
            type: String,
            required: false,
            default: '',
        },

        disclaimer: {
            type: String,
            required: false,
            default: '',
        },

        inputPlaceholder: {
            type: String,
            default: 'E-Mail-Adresse',
        },

        buttonSubmit: {
            type: String,
            default: 'Subscribe',
        },

        header: {
            type: String,
            default: 'h2',
        },

        ctaLargeButton: {
            type: Boolean,
            default: false,
        },

        errorGenericMessage: {
            type: String,
            default:
                'Es ist ein Fehler aufgetreten, bitte versuchen Sie es später noch einmal',
        },

        errorEmailExist: {
            type: String,
            default: 'E-Mail-Adresse existiert bereits',
        },

        successfulMessage: {
            type: String,
            default: 'Nächster Schritt: Sie erhalten Ihr Infopaket',
        },
    },

    data() {
        return {
            form: {
                emailAddress: '',
                successfullySubmitted: false,
                statusMessage: '',
                loading: false,
            },
            rules: {
                required: value => this.isNotEmpty(value) || 'Erforderlich',
                email: value => {
                    return (
                        this.isEmailValid(value) || 'Ungültige E-Mail-Adresse'
                    )
                },
            },
        }
    },

    methods: {
        async submit() {
            if (!this.$refs.form.validate()) {
                return
            }

            this.form.loading = true

            const emailVerification = await subscribeEmail(
                this.form.emailAddress,
            )

            this.form.loading = false

            if (!emailVerification.status) {
                this.form.successfullySubmitted = false
                this.form.statusMessage = this.handleEmailValidationError(
                    emailVerification.errorDescription,
                )

                this.trackEvent({
                    category: 'e-mail signup',
                    action:
                        'user got error message when trying to register for newsletter',
                    label: this.form.statusMessage,
                })

                return
            }

            this.form.statusMessage = this.handleEmailSuccessMessage()
            this.form.successfullySubmitted = true

            this.trackEvent({
                category: 'e-mail signup',
                action: 'user successfully signed up for newsletter',
                label: this.buttonSubmit,
            })
        },

        handleEmailSuccessMessage() {
            return this.successfulMessage
        },

        handleEmailValidationError(description) {
            if (description === 'Member Exists') {
                return this.errorEmailExist
            }

            return this.errorGenericMessage
        },

        updateEmail(email) {
            this.form.emailAddress = email
        },
    },
}
</script>

<style lang="stylus">
.emailForm {
    &__hint {
        color: $cs-slate-300;
        margin: 0 0 $cs-spaces.quarter;
    }

    &__button {
        margin: 0;
    }

    .header__title {
        margin-bottom: $cs-spaces.double;
    }

    .icon__email {
        color: $cs-slate-300;
        height: 20px;
        width: 20px;
    }

    &__input--approved.v-text-field {

         .v-input__slot {
            border-color: $cs-secondary !important;
        }

        .icon__email {
            color: $cs-secondary;
        }
    }

    &__statusMessage {
        color: $cs-secondary;
        display: block;
        margin: $cs-space 0 0;
        text-align: center;

        &--error {
            color: $cs-salmon;
        }
    }

    &__disclaimer {
        margin: $cs-space 0 0;
        font-size: 14px;
    }
}
</style>
