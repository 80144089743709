import Vue from 'vue'

import Vuetify from 'vuetify/lib';

Vue.use(Vuetify, {
  "theme": {
    "primary": "#FFAA00",
    "secondary": "#3B9BA9",
    "accent": "#297ee1",
    "error": "#EF6D67",
    "info": "#2196F3",
    "success": "#55AD9A",
    "warning": "#FFC107",
    "slate050": "#F4F6F9",
    "slate100": "#E9ECEF",
    "slate200": "#C9D1D8",
    "slate300": "#93A3B2",
    "slate400": "#495159"
  }
})
