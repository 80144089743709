// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue'
import VueI18n from 'vue-i18n'
// eslint-disable-next-line import/no-unresolved
import messages from '@/data/Website_German.json'

/**
 * Prepare translations for i18n
 *
 * @type {{string: string}}
 */
const de = Object.keys(messages).reduce(
    /**
     * @param {{string: string}} carry
     * @param {string} k
     * @returns {{string: string}}
     */
    (carry, k) => {
        /**
         * @type {string|{one: string, other: string}} message
         */
        const message = messages[k]
        if (typeof message === 'string') {
            // eslint-disable-next-line no-param-reassign
            carry[k] = message
        }

        if (typeof message === 'object') {
            // eslint-disable-next-line no-param-reassign
            carry[k] = [message.other, message.one, message.other].join(' | ')
        }

        return carry
    },
    {},
)

Vue.use(VueI18n)

export default ({ app }) => {
    // eslint-disable-next-line no-param-reassign
    app.i18n = new VueI18n({
        locale: 'de',
        fallbackLocale: 'de',
        messages: {
            de,
        },
    })
}
