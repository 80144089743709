<template>
    <v-app>
        <div class="page page--header">
            <Navigation />

            <main class="page__wrap">
                <PromotionBanner
                    v-if="displayPromoBanner"
                    :is-clickable="isClickable"
                    :with-chevron="isClickable"
                />

                <nuxt-child
                    :display-promo-banner="displayPromoBanner"
                    @isBannerClickable="changeBannerClickable"
                />
            </main>

            <Footer native-page />
        </div>
    </v-app>
</template>

<script>
import Navigation from '@/components/organisms/navigation/navigation.vue'
import Footer from '@/components/organisms/footer/footer.vue'
import PromotionBanner from '@/components/organisms/promotion/scrollBanner.vue'

export default {
    name: 'NativeLayout',

    components: {
        Navigation,
        Footer,
        PromotionBanner,
    },

    data: () => ({
        isClickable: true,
    }),

    computed: {
        displayPromoBanner() {
            if (this.$route.path === '/partner') {
                return false
            }

            return true
        },
    },

    methods: {
        changeBannerClickable(clickable) {
            this.isClickable = clickable
        },
    },
}
</script>
