<template>
    <div>
        <NavigationDesktop
            :nav-variation="navVariation"
            :s-r-draft-url="SRDraftUrl"
            :phone-number="phoneNumber"
            class="navigationDesktop"
        />
        <NavigationMobile
            :nav-variation="navVariation"
            :s-r-draft-url="SRDraftUrl"
            :phone-number="phoneNumber"
            class="navigationMobile"
        />
    </div>
</template>

<script>
import NavigationDesktop from './desktop.vue'
import NavigationMobile from './mobile.vue'

export default {
    name: 'Navigation',
    components: {
        NavigationDesktop,
        NavigationMobile,
    },
    props: {
        leadGen: {
            type: Boolean,
            default: false,
        },
        phoneContact: {
            type: Boolean,
            default: false,
        },
        phoneContactB: {
            type: Boolean,
            default: false,
        },
        phoneNumber: {
            type: String,
            default: '(030) 220 128 831',
        },
    },
    data() {
        return {
            navVariation: '',
            SRDraftUrl: '',
        }
    },
    watch: {
        $route(to, from) {
            if (to.path !== from.path) {
                this.setupNavigation()
            }
        },
    },
    mounted() {
        this.setupNavigation()
    },
    methods: {
        setupNavigation() {
            this.navVariation = this.getNavVariation()
            this.SRDraftUrl = this.getSRDraftUrl()
        },
        getNavVariation() {
            if (this.leadGen) {
                return 'lead-gen'
            }

            if (this.phoneContact) {
                return 'phone-contact'
            }

            if (this.phoneContactB) {
                return 'phone-contact-b'
            }

            const hasSRDraftData = Boolean(
                localStorage.getItem('serviceRequestDraftData'),
            )
            const isloggedIn = Boolean(localStorage.getItem('userToken'))

            if (hasSRDraftData) {
                return 'funnel'
            }

            if (isloggedIn) {
                return 'dashboard'
            }

            return 'default'
        },
        getSRDraftUrl() {
            return '/kunde'
        },
    },
}
</script>

<style lang="stylus" scoped>
$navBreakpointMobile := '(max-width: 1129px)'
$navBreakpointDesktop := '(min-width: 1130px)'

.navigationDesktop {
  @media $navBreakpointMobile {
    display: none;
  }
}

.navigationMobile {
  @media $navBreakpointDesktop {
    display: none;
  }
}
</style>
