<template>
    <v-toolbar class="toolbar" height="70" fixed>
        <slot />
    </v-toolbar>
</template>

<script>
export default {
    name: 'NavToolbar',
}
</script>

<style lang="stylus" scoped>
.toolbar {
    box-shadow: 0 $cs-spaces.half $cs-space 0 rgba(0, 0, 0, 0.11);
    background-color: $cs-white;
    z-index: 100;
}
</style>
