<template>
    <footer class="nativeFooter" data-test="footer">
        <v-container>
            <div class="nativeFooter__layout">
<!--                <div-->
<!--                    v-if="nativePage"-->
<!--                    class="nativeFooter__column nativeFooter__column&#45;&#45;border"-->
<!--                    data-test="footer-emailForm"-->
<!--                >-->
<!--                    <EmailForm v-bind="emailSignup" />-->
<!--                </div>-->

                <div class="nativeFooter__column" data-test="footer-menu">
                    <ul class="nativeFooter__list">
                        <li v-for="(item, key) in menuLinks" :key="key">
                            <a
                                :href="item.link"
                                :class="[
                                    'nativeFooter__link',
                                    {
                                        'nativeFooter__link--space':
                                            item.space && nativePage,
                                    },
                                ]"
                                @click="
                                    () => {
                                        trackLink(item.text, item.link)
                                    }
                                "
                            >
                                {{ item.text }}
                            </a>
                        </li>
                    </ul>
                </div>

                <div
                    :class="[
                        'nativeFooter__column--right',
                        { 'nativeFooter__column--right': !nativePage },
                    ]"
                    data-test="footer-companyDetails"
                >
                    <component
                        :is="leadGen ? 'div' : 'a'"
                        :href="leadGen ? false : '/'"
                        :class="[
                            'nativeFooter__logo',
                            {
                                'nativeFooter__logo--interactive': !leadGen,
                            },
                        ]"
                        data-test="footer-logo"
                        @click="
                            () => {
                                trackLink('Careship-logo', '/')
                            }
                        "
                    >
                        <SVGIcon icon-name="careship-logo" class="icon__logo" />
                    </component>

                    <ul>
                        <li>
                            <a
                                :href="`tel:${footer.contactInfo.phoneNumber}`"
                                class="nativeFooter__link  nativeFooter__link--strong"
                                @click="
                                    () => {
                                        trackLink(
                                            'telephone',
                                            footer.contactInfo.phoneNumber,
                                        )
                                    }
                                "
                            >
                                {{ footer.contactInfo.phoneNumber }}
                            </a>
                        </li>
                        <li>
                            <ul>
                                <li>
                                    {{
                                        footer.contactInfo.workingHours
                                            .firstLine
                                    }}
                                </li>
                                <li
                                    v-if="
                                        footer.contactInfo.workingHours
                                            .secondLine
                                    "
                                >
                                    {{
                                        footer.contactInfo.workingHours
                                            .secondLine
                                    }}
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a
                                :href="`mailto:${footer.contactInfo.email}`"
                                class="nativeFooter__link nativeFooter__link--strong"
                                @click="
                                    () => {
                                        trackLink(
                                            'email',
                                            footer.contactInfo.email,
                                        )
                                    }
                                "
                            >
                                {{ footer.contactInfo.email }}
                            </a>
                        </li>
                    </ul>

                    <ul
                        v-if="nativePage"
                        class="nativeFooter__list nativeFooter__social"
                        data-test="footer-socialMenu"
                    >
                        <li v-for="(item, key) in menu.social" :key="key">
                            <a
                                :href="item.link"
                                @click="
                                    () => {
                                        trackLink(item.text, item.link)
                                    }
                                "
                            >
                                <SVGIcon
                                    :icon-name="item.icon"
                                    class="icon__social"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div v-if="!leadGen" data-test="footer-citiesMenu">
                <ul class="nativeFooter__cities">
                    <li
                        v-for="(item, key) in orderedCities"
                        :key="key"
                        class="nativeFooter__linkContainer"
                    >
                        <a
                            v-if="item.link"
                            :href="item.link"
                            class="nativeFooter__link"
                            @click="
                                () => {
                                    trackLink(item.text, item.link)
                                }
                            "
                        >
                            {{ item.text }}
                        </a>
                        <span v-else>{{ item.text }}</span>
                    </li>
                </ul>
            </div>

            <div class="nativeFooter__copyright">
                &copy;{{ new Date().getFullYear() }} Care Companion GmbH
            </div>
        </v-container>
    </footer>
</template>

<script>
import _pickBy from 'lodash.pickby'
import _orderBy from 'lodash.orderby'

import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import EmailForm from '@/components/molecules/form/emailForm.vue'
import gtmTracking from '@/mixins/gtmTracking'

// config
import navigationConfig from '@/data/navigationConfig'

export default {
    name: 'Footer',

    components: {
        SVGIcon,
        EmailForm,
    },

    mixins: [gtmTracking],

    props: {
        nativePage: {
            type: Boolean,
            default: false,
        },

        leadGen: {
            type: Boolean,
            default: false,
        },

        phoneNumber: {
            type: String,
            default: '(030) 220 128 831',
        },
    },

    data() {
        return {
            menu: {
                footer: navigationConfig.footerMenu,
                social: navigationConfig.socialMedia,
                cities: navigationConfig.cities,
            },
            footer: {
                contactInfo: {
                    phoneNumber: this.phoneNumber,
                    email: 'info@careship.de',
                    workingHours: {
                        firstLine: this.$t('FOOTER_WORKING_HOURS_LINE_1'),
                        secondLine: this.$t('FOOTER_WORKING_HOURS_LINE_2'),
                    },
                },
            },
            emailSignup: {
                title: 'Länger glücklich im eigenen Zuhause',
                subline:
                    'Fordern Sie hier Ihr persönliches Infopaket per E-Mail an, das Sie bei der Organisation von Hilfe im Alltag unterstützt. Wir bieten auch kontaktlose Dienstleistungen an. Unser Kundenservice hilft Ihnen gerne weiter.',
                inputPlaceholder: 'Ihre E-Mail-Adresse',
                buttonSubmit: 'Jetzt Infopaket anfordern',
                disclaimer:
                    'Wir geben Ihre E-Mail-Adresse niemals an Dritte weiter.',
            },
        }
    },

    computed: {
        orderedCities() {
            return _orderBy(this.menu.cities, 'text')
        },
        menuLinks() {
            if (this.nativePage) {
                return this.menu.footer
            }

            if (this.leadGen) {
                return _pickBy(
                    this.menu.footer,
                    value => value.displayOnLeadGen,
                )
            }

            return _pickBy(this.menu.footer, value => value.displayOnLP)
        },
    },

    methods: {
        trackLink(text, link) {
            this.trackEvent({
                category: 'navigation',
                action: 'user clicked in the footer',
                text,
                label: `${text} - ${link}`,
            })
        },
    },
}
</script>

<style lang="stylus">
.nativeFooter {
    background: $cs-slate-400;
    color: $cs-white;
    font-size: 16px;
    min-height: 200px;
    padding: $cs-spaces.onehalf 0;

    &__layout {
        display: flex;
        flex-flow: column;

        @media $display-breakpoints.md-and-up {
            flex-flow: row;
            justify-content: space-between;
            margin: 0 (-($cs-spaces.onehalf))
        }
    }

    &__column {
        border: $cs-white solid;
        border-width: 0 0 1px;
        padding: $cs-space 0;
        flex-grow: 1;
        flex-basis: 33%;

        @media $display-breakpoints.md-and-up {
            border-width: 0;
            padding: 0 $cs-spaces.onehalf;

            &--right {
                flex: 0 0 255px;
            }

            &--border {
                border-width: 0 1px 0 0;
            }
        }

    }

    &__list {
        font-weight: bold;
        list-style: none;
        padding: 0;

        li {
            margin: $cs-spaces.half 0;
        }
    }

    &__social {
        margin: $cs-space 0 $cs-spaces.half;

        li {
            display: inline-block;
            margin: 0 $cs-spaces.onehalf 0 0;

            &:last-child {
                margin: 0;
            }
        }

        a {
            color: $cs-white;
            transition: color 200ms ease-in-out;

            &:hover {
                color: $cs-primary;
            }
        }
    }

    &__cities {
        color: $cs-slate-200;
        margin: $cs-spaces.onehalf 0 0;
        columns: 3;

        @media $display-breakpoints.md-and-up {
            columns: unset;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: $cs-spaces.double 0 0;
        }
    }

    &__linkContainer {
        margin-top: 0;
        margin-bottom: 4px;

        @media $display-breakpoints.md-and-up {
            margin: 0 $cs-spaces.half $cs-spaces.half;
            flex: 0 1 auto;
        }
    }

    &__link {
        border-bottom: 1px solid transparent;
        color: currentColor;
        display: inline-block;
        position: relative;
        text-decoration: none;
        transition: ease-in-out 200ms border-color;

        &:after {
            background: currentColor;
            bottom: 0;
            content: '';
            height: 1px;
            left: 0;
            position: absolute;
            right: 100%;
            transition: right .2s;
        }

        &:hover:after {
            right: 0;
        }

        &--space {
            margin-bottom: $cs-spaces.double;
        }

        &--strong {
            font-weight: bold;
        }
    }

    &__logo {
        border: 0;
        color: $cs-white;
        display: block;
        display: inline-block;
        margin: $cs-spaces.half 0 $cs-space;
        transition: color 200ms ease-in-out;

        @media $display-breakpoints.md-and-up {
            margin: 0 0 $cs-space;
        }

        &--interactive:hover {
            color: $cs-primary;
        }

        .icon__logo {
            height: 30px;
            width: 110px;
        }
    }

    &__copyright {
        color: $cs-slate-300;
        margin: $cs-space 0 0;

        @media $display-breakpoints.md-and-up {
            text-align: center;
        }
    }

    .emailForm {
        h2 {
            font-size: 24px;
            color: $cs-white;
        }

        .header__subline {
            font-size: 16px;
        }

        .emailForm__disclaimer {
            margin: $cs-space 0 $cs-spaces.half;
        }
    }

    .icon__social {
        height: 24px;
        width: 24px;
    }
}
</style>
