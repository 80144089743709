import getSessionId from 'tracking/lib/getSessionId'
import { request } from '@/src/oauth'
import { mailchimpAudienceIds } from '@/data/appConfig'

export default async function subscribeEmail(email) {
    const postBody = {
        audience_list_id: mailchimpAudienceIds.infopaket_careship,
        email,
        tracking_fields: {
            SESSION_ID: getSessionId() || '',
            PAGEPATH: window.location.pathname,
        },
    }

    return request
        .post('/landing-page/email-audience/member', postBody)
        .then(() => {
            return {
                status: true,
            }
        })
        .catch(error => {
            return {
                status: false,
                errorStatus: error.response ? error.response.status : 401,
                errorDescription:
                    error.response && error.response.data
                        ? error.response.data.details
                        : '',
            }
        })
}
