<template lang="html">
    <component
        :is="mainComponent"
        :href="href"
        :class="[
            'promotionBanner',
            { 'promotionBanner--clickable': isClickable },
        ]"
        data-test="couponBanner"
        @click="handleClick"
    >
        <div class="promotionBanner__wrapper">
            <SVGIcon icon-name="gift" class="icon__gift" />

            <div class="promotionBanner__header">
                <span class="promotionBanner__title">
                    {{ title }}
                </span>

                <span class="promotionBanner__subline">
                    {{ subline }}
                </span>
            </div>
        </div>

        <div
            v-if="code"
            class="promotionBanner__wrapper promotionBanner__wrapper--code"
        >
            <span class="promotionBanner__codeLabel">
                {{ $t('TEXT_COUPON_PROMOTION_BANNER_LABEL') }}
            </span>

            <div class="promotionBanner__code">
                {{ code }}
            </div>

            <SVGIcon
                v-if="withChevron"
                icon-name="chevron-right"
                class="icon__chevron"
            />
        </div>
    </component>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

import gtmTracking from '@/mixins/gtmTracking'

export default {
    components: {
        SVGIcon,
    },

    mixins: [gtmTracking],

    props: {
        withChevron: {
            type: Boolean,
            default: false,
        },

        isClickable: {
            type: Boolean,
            default: true,
        },

        promotionLink: {
            type: String,
            default: '/kunde/anfrage',
        },

        title: {
            type: String,
            required: true,
        },

        subline: {
            type: String,
            required: true,
        },

        code: {
            type: String,
            required: true,
        },
    },

    computed: {
        mainComponent() {
            return this.isClickable ? 'a' : 'div'
        },

        href() {
            return this.isClickable ? this.promotionLink : false
        },
    },

    methods: {
        handleClick(e) {
            this.$emit('click', e)
            if (this.isClickable) {
                this.trackEventForUserActions(
                    'Coupon Banner',
                    this.title,
                    this.promotionLink,
                )
            }
        },
    },
}
</script>

<style lang="stylus">
.promotionBanner {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    @media $display-breakpoints.sm-and-up {
        flex-direction: row;
        justify-content: center;
    }

    &--clickable {
        color: $cs-white;
        text-decoration: none;

        @media $display-breakpoints.md-and-up {
            justify-content: center;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;

        &--code {
            margin-left: $cs-spaces.triple;

            @media $display-breakpoints.xs-only {
                margin-left: 0;
                margin-top: $cs-space;
            }
        }
    }

    &__header {
        display: flex;
        flex-direction: column;

        @media $display-breakpoints.sm-and-up {
            align-items: baseline;
            flex-flow: wrap row;
        }
    }

    &__title {
        font-size: 18px;
        margin-top: $cs-spaces.quarter;
        text-transform: uppercase;
        font-weight: 800;

        @media $display-breakpoints.sm-and-up {
            font-size: 22px;
            margin-right: $cs-spaces.half;
            margin-top: 0;
        }

        @media $display-breakpoints.lg-and-up {
            font-size: 28px;
        }
    }

    &__subline {
        font-size: 20px;

        @media $display-breakpoints.sm-and-up {
            font-size: 22px;
        }

        @media $display-breakpoints.lg-and-up {
            font-size: 24px;
        }
    }

    &__codeLabel {
        color: $cs-primary;
        font-size: 20px;
        font-weight: 800;
        margin-right: $cs-spaces.half;

        @media $display-breakpoints.lg-and-up {
            font-size: 28px;
        }
    }

    &__code {
        border-radius: $cs-border-radius-half;
        border: 2px solid $cs-primary;
        color: $cs-white;
        flex: 1 0 auto;
        font-size: 20px;
        padding: $cs-spaces.quarter $cs-spaces.half;
        text-align: center;

        @media $display-breakpoints.sm-and-up {
            flex: 0;
            padding: $cs-spaces.half;
        }
    }

    .icon__gift {
        flex: 0 0 auto;
        height: $cs-spaces.triple;
        margin-right: $cs-space;
        width: $cs-spaces.triple;
        color: $cs-white;
    }

    .icon__chevron {
        color: $cs-primary;
        flex: 0 0 auto;
        height: $cs-spaces.triple;
        transition: all 300ms ease-in-out;
        width: $cs-spaces.triple;
    }

    &--clickable:hover .icon__chevron {
        color: $cs-white;
        transform: translateX($cs-spaces.half);
    }
}
</style>
