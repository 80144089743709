import { fetchAccessToken } from '@/src/oauth'

export default function refreshAccessToken() {
    localStorage.removeItem('access_token')

    return fetchAccessToken()
        .then(response => {
            localStorage.setItem('access_token', response.data.access_token)
            return Promise.resolve(response.data)
        })
        .catch(() => false)
}
