import { record } from 'tracking/sdk/recordView/record'
import clickTracking from 'tracking/sdk/recordView/clickTracking'

export default ({ app }) => {
    /**
     * Only run on client-side and only in production mode
     */
    if (process.env.NODE_ENV !== 'production') {
        // return
    }

    /**
     * Every time the route changes (fired on initialization too)
     */
    app.router.afterEach((to, from) => {
        const { hostname } = window.location
        const parameters = to.fullPath
            .split('?')
            .slice(1)
            .join('?')
        const virtualPageURL = to.fullPath.split('?')[0]

        record(
            window.LANDING_PAGE_CONFIG.trackingBaseUrl,
            hostname,
            virtualPageURL,
            parameters,
        )

        clickTracking(window.LANDING_PAGE_CONFIG.trackingBaseUrl).recordClick()

        // skip gtm (already triggered) if initial load
        if (!from.name) {
            return
        }

        dataLayer.push({
            event: 'VirtualPage',
            hostname,
            virtualPageURL,
            virtualPageTitle: document.title,
        })
    })
}
