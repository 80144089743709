const stripTrailingSlash = str => str.replace(/\/+$/, '')

let apiUrl = ''
let oAuthClientId = ''
let oAuthClientSecret = ''
let mailchimpAudienceIds = {}
const requestTimeout = 20000

if (process.browser) {
    apiUrl = stripTrailingSlash(
        window.LANDING_PAGE_CONFIG.apiUrl || 'http://localhost:8083',
    )
    oAuthClientId =
        window.LANDING_PAGE_CONFIG.oAuthClientId ||
        '1220105e-3a50-476a-8a0f-4d286c2d1a28'
    oAuthClientSecret = window.LANDING_PAGE_CONFIG.oAuthClientSecret || 'secret'
    mailchimpAudienceIds = window.LANDING_PAGE_CONFIG.mailchimpAudienceIds || {
        infopaket_careship: '',
    }
}

export {
    apiUrl,
    oAuthClientId,
    oAuthClientSecret,
    requestTimeout,
    mailchimpAudienceIds,
}
