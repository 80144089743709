import axios from 'axios'

import {
    apiUrl,
    requestTimeout,
    oAuthClientId,
    oAuthClientSecret,
} from '@/data/appConfig'

export default function fetchConfigToken() {
    const requestPayload = [
        `client_id=${oAuthClientId}`,
        `client_secret=${oAuthClientSecret}`,
        'grant_type=client_credentials',
        'scope=email_audience_write caregivers_in_area_read config_read salesforce_lead_gen caregivers_available_read',
    ].join('&')

    return axios.post(`${apiUrl}/auth/token`, requestPayload, {
        timeout: requestTimeout,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    })
}
