import axios from 'axios'

import { apiUrl, requestTimeout } from '@/data/appConfig'

import { getAndSaveConfigToken, refreshAccessToken } from '@/src/oauth'

/** @type {AxiosInstance} */
const basicRequestInstance = axios.create({
    baseURL: apiUrl,
    timeout: requestTimeout,
})

basicRequestInstance.interceptors.request.use(
    async requestConfig => {
        const token = await getAndSaveConfigToken()

        requestConfig.headers.Authorization = `Bearer ${token}`

        return Promise.resolve(requestConfig)
    },
    error => Promise.reject(error),
)

let isRefreshing = false
let subscribers = []

basicRequestInstance.interceptors.response.use(
    async responseConfig => Promise.resolve(responseConfig),
    async error => {
        const status =
            error.response && error.response.status ? error.response.status : 0
        const originalRequest = error.config

        if (status === 401 || error.message === 'Network Error') {
            const requestSubscribers = new Promise(resolve => {
                subscribeTokenRefresh(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`
                    originalRequest.url = originalRequest.url
                        .split(apiUrl)
                        .join('')
                    resolve(axios(originalRequest))
                })
            })

            if (!isRefreshing) {
                isRefreshing = true

                const tokenRefreshed = await refreshAccessToken()

                if (tokenRefreshed) {
                    isRefreshing = false
                    onRefreshed(tokenRefreshed.access_token)
                    subscribers = []
                }
            }

            return requestSubscribers
        }

        return Promise.reject(error)
    },
)

function subscribeTokenRefresh(callback) {
    subscribers.push(callback)
}

function onRefreshed(token) {
    subscribers.map(callback => callback(token))
}

export default basicRequestInstance
