<template lang="html">
    <div
        ref="scrollBanner"
        :class="['scrollBanner', { 'scrollBanner--hidden': !showPromoBanner }]"
    >
        <v-container class="container--slim">
            <coupon-banner
                :title="title"
                :subline="subline"
                :code="code"
                :promotion-link="promotionLink"
                :is-clickable="isClickable"
                :with-chevron="withChevron"
                @click="$emit('click', $event)"
            />
        </v-container>
    </div>
</template>

<script>
import CouponBanner from '@/components/molecules/promotions/couponBanner.vue'

export default {
    name: 'ScrollBanner',

    components: {
        CouponBanner,
    },

    props: {
        withChevron: {
            type: Boolean,
            default: false,
        },

        isClickable: {
            type: Boolean,
            default: false,
        },

        promotionLink: {
            type: String,
            default: '/kunde/anfrage',
        },

        title: {
            type: String,
            default() {
                return this.$t('TITLE_COUPON_PROMOTION_BANNER')
            },
        },

        subline: {
            type: String,
            default() {
                return this.$t('SUBLINE_COUPON_PROMOTION_BANNER')
            },
        },

        code: {
            type: String,
            default() {
                return this.$t('TEXT_COUPON_PROMOTION_BANNER_COUPONCODE')
            },
        },
    },

    data: () => ({
        showPromoBanner: true,
        lastScrollPosition: 0,
    }),

    mounted() {
        window.addEventListener('scroll', this.onScroll)
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll)
    },

    methods: {
        onScroll() {
            const currentScrollPosition =
                window.pageYOffset || document.documentElement.scrollTop

            if (currentScrollPosition < 0) {
                return
            }

            if (
                Math.abs(currentScrollPosition - this.lastScrollPosition) < 60
            ) {
                return
            }

            this.showPromoBanner =
                currentScrollPosition < this.lastScrollPosition
            this.lastScrollPosition = currentScrollPosition
        },
    },
}
</script>

<style lang="stylus">
.scrollBanner {
    background: $cs-slate-400;
    color: $cs-white;
    padding: $cs-spaces.half 0;
    position: sticky;
    top: $cs-header-height;
    transform: translate3d(0, 0, 0);
    transition: 200ms all ease-out;
    width: 100%;
    z-index: 5;

    &--hidden {
        box-shadow: none;
        transform: translate3d(0, -100%, 0);
    }
}
</style>
