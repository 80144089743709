export default {
    methods: {
        isNotEmpty(value) {
            return !!value
        },

        isEmailValid(email) {
            if (!email) {
                return true
            }

            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            return pattern.test(email)
        },

        isPostcode(postcode) {
            if (!postcode) {
                return true
            }

            const pattern = /^(?!01000|99999)(0[1-9]\d{3}|[1-9]\d{4})$/

            return pattern.test(postcode)
        },

        isPhoneNumber(phone) {
            if (!phone) {
                return true
            }

            const pattern = /^(?:(?:00|\+|49)|0)[0-9\s-\\/()]{6,20}$/

            return pattern.test(phone)
        },
    },
}
