export default {
    footerMenu: [
        {
            displayOnLP: true,
            text: 'Über uns',
            link: 'ueber-uns/',
        },
        {
            displayOnLP: true,
            text: 'Häufig gestellte Fragen',
            link: 'faq/',
        },
        {
            text: 'Careship Magazin',
            link: 'senioren-ratgeber/',
        },
        {
            displayOnLP: true,
            text: 'Presse',
            link: 'newsroom/',
        },
        {
            text: 'Karriere',
            link: 'https://mycareship.jobs.personio.de/',
            space: true,
        },
        {
            text: 'MeinCareship Login',
            link: 'https://my.careship.de/',
        },
        {
            text: 'Fragen zur Alltagshilfetätigkeit',
            link: 'hilfe/',
        },
        {
            text: 'Alltagshilfe werden',
            link: '/kunde/alltagshilfe/bewerbung',
            space: true,
        },
        {
            displayOnLP: true,
            displayOnLeadGen: true,
            text: 'Impressum',
            link: 'impressum/',
        },
        {
            displayOnLP: true,
            displayOnLeadGen: true,
            text: 'Datenschutz',
            link: 'datenschutz/',
        },
        {
            displayOnLP: true,
            displayOnLeadGen: true,
            text: 'Allgemeine Nutzungsbedingungen',
            link: 'agb/',
        },
    ],

    socialMedia: [
        {
            text: 'Facebook',
            link: 'https://www.facebook.com/careship.alltagshilfe',
            icon: 'footer-social-fb',
        },
        {
            text: 'Twitter',
            link: 'https://twitter.com/careship_de',
            icon: 'footer-social-tw',
        },
        {
            text: 'Instagram',
            link: 'https://www.instagram.com/careship/',
            icon: 'footer-social-in',
        },
    ],

    cities: [
        {
            text: 'Berlin',
            link: 'stadt/berlin/',
        },
        {
            text: 'Hamburg',
            link: 'stadt/hamburg/',
        },
        {
            text: 'Essen',
            link: 'stadt/essen/',
        },
        {
            text: 'Dresden',
            link: 'stadt/dresden/',
        },
        {
            text: 'Köln',
            link: 'stadt/koeln/',
        },
        {
            text: 'München',
            link: 'stadt/muenchen/',
        },
        {
            text: 'Stuttgart',
            link: 'stadt/stuttgart/',
        },
        {
            text: 'Frankfurt',
            link: 'stadt/frankfurt/',
        },
        {
            text: 'Düsseldorf',
            link: 'stadt/duesseldorf/',
        },
        {
            text: 'Leipzig',
        },
        {
            text: 'Duisburg',
        },
        {
            text: 'Dortmund',
        },
        {
            text: 'Potsdam',
        },
        {
            text: 'Hannover',
        },
        {
            text: 'Bochum',
        },
        {
            text: 'Nürnberg',
        },
        {
            text: 'Bonn',
        },
        {
            text: 'Bremen',
        },
        {
            text: 'Münster',
        },
        {
            text: 'Mannheim',
        },
        {
            text: '… und viele mehr',
        },
    ],
}
