<template>
    <v-app>
        <div class="page page--header">
            <Navigation lead-gen />

            <main class="page__wrap">
                <PromotionBanner v-if="displayPromoBanner" />

                <nuxt />
            </main>

            <Footer lead-gen />
        </div>
    </v-app>
</template>

<script>
import Navigation from '@/components/organisms/navigation/navigation.vue'
import Footer from '@/components/organisms/footer/footer.vue'
import PromotionBanner from '@/components/organisms/promotion/scrollBanner.vue'

export default {
    name: 'LeadgenLayout',

    components: {
        Navigation,
        Footer,
        PromotionBanner,
    },

    data() {
        return {
            displayPromoBanner: true,
        }
    },
}
</script>
