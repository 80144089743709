import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0af59ad0 = () => interopDefault(import('../pages/kunde-suche-a.vue' /* webpackChunkName: "pages/kunde-suche-a" */))
const _0b11c9d2 = () => interopDefault(import('../pages/kunde-suche-c.vue' /* webpackChunkName: "pages/kunde-suche-c" */))
const _76eec864 = () => interopDefault(import('../pages/partner.vue' /* webpackChunkName: "pages/partner" */))
const _d3909736 = () => interopDefault(import('../pages/preise/index.vue' /* webpackChunkName: "pages/preise/index" */))
const _6ebae3e9 = () => interopDefault(import('../pages/willkommen.vue' /* webpackChunkName: "pages/willkommen" */))
const _f69e73a6 = () => interopDefault(import('../pages/b2b-api/changelog/index.vue' /* webpackChunkName: "pages/b2b-api/changelog/index" */))
const _36299155 = () => interopDefault(import('../pages/preise/pflegekasse.vue' /* webpackChunkName: "pages/preise/pflegekasse" */))
const _0ad81575 = () => interopDefault(import('../pages/service/alltagshelfer-finden.vue' /* webpackChunkName: "pages/service/alltagshelfer-finden" */))
const _266862f6 = () => interopDefault(import('../pages/service/engagierte-haushaltshilfe.vue' /* webpackChunkName: "pages/service/engagierte-haushaltshilfe" */))
const _077ba24f = () => interopDefault(import('../pages/service/haushaltshilfe-finanzierungstipps.vue' /* webpackChunkName: "pages/service/haushaltshilfe-finanzierungstipps" */))
const _f1abc036 = () => interopDefault(import('../pages/service/coop/betreut.vue' /* webpackChunkName: "pages/service/coop/betreut" */))
const _86a27e78 = () => interopDefault(import('../pages/service/coop/nui.vue' /* webpackChunkName: "pages/service/coop/nui" */))
const _7d8b9f2f = () => interopDefault(import('../pages/service/coop/pflege-durch-angehoerige.vue' /* webpackChunkName: "pages/service/coop/pflege-durch-angehoerige" */))
const _53bfea86 = () => interopDefault(import('../pages/service/email/anmelden.vue' /* webpackChunkName: "pages/service/email/anmelden" */))
const _d673bb62 = () => interopDefault(import('../pages/service/alltagshilfe-in-ihrer-stadt/_id.vue' /* webpackChunkName: "pages/service/alltagshilfe-in-ihrer-stadt/_id" */))
const _60e8e9f1 = () => interopDefault(import('../pages/service/alltagshilfe-telefonkontakt-plaene/_id.vue' /* webpackChunkName: "pages/service/alltagshilfe-telefonkontakt-plaene/_id" */))
const _18218dce = () => interopDefault(import('../pages/service/alltagshilfe-telefonkontakt/_id.vue' /* webpackChunkName: "pages/service/alltagshilfe-telefonkontakt/_id" */))
const _88fbf582 = () => interopDefault(import('../pages/service/alltagshilfe/_id.vue' /* webpackChunkName: "pages/service/alltagshilfe/_id" */))
const _b33c7c42 = () => interopDefault(import('../pages/service/b2b-kooperation/_id.vue' /* webpackChunkName: "pages/service/b2b-kooperation/_id" */))
const _9d75389e = () => interopDefault(import('../pages/service/engagierte-alltagshilfe/_id.vue' /* webpackChunkName: "pages/service/engagierte-alltagshilfe/_id" */))
const _7030c3c8 = () => interopDefault(import('../pages/service/haushaltshilfe-finanzierungstipps-tel/_id.vue' /* webpackChunkName: "pages/service/haushaltshilfe-finanzierungstipps-tel/_id" */))
const _25427d1f = () => interopDefault(import('../pages/service/partner/_id.vue' /* webpackChunkName: "pages/service/partner/_id" */))
const _50165c78 = () => interopDefault(import('../pages/zones/_id.vue' /* webpackChunkName: "pages/zones/_id" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2efaf336 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/kunde-suche-a",
    component: _0af59ad0,
    name: "kunde-suche-a"
  }, {
    path: "/kunde-suche-c",
    component: _0b11c9d2,
    name: "kunde-suche-c"
  }, {
    path: "/partner",
    component: _76eec864,
    name: "partner"
  }, {
    path: "/preise",
    component: _d3909736,
    name: "preise"
  }, {
    path: "/willkommen",
    component: _6ebae3e9,
    name: "willkommen"
  }, {
    path: "/b2b-api/changelog",
    component: _f69e73a6,
    name: "b2b-api-changelog"
  }, {
    path: "/preise/pflegekasse",
    component: _36299155,
    name: "preise-pflegekasse"
  }, {
    path: "/service/alltagshelfer-finden",
    component: _0ad81575,
    name: "service-alltagshelfer-finden"
  }, {
    path: "/service/engagierte-haushaltshilfe",
    component: _266862f6,
    name: "service-engagierte-haushaltshilfe"
  }, {
    path: "/service/haushaltshilfe-finanzierungstipps",
    component: _077ba24f,
    name: "service-haushaltshilfe-finanzierungstipps"
  }, {
    path: "/service/coop/betreut",
    component: _f1abc036,
    name: "service-coop-betreut"
  }, {
    path: "/service/coop/nui",
    component: _86a27e78,
    name: "service-coop-nui"
  }, {
    path: "/service/coop/pflege-durch-angehoerige",
    component: _7d8b9f2f,
    name: "service-coop-pflege-durch-angehoerige"
  }, {
    path: "/service/email/anmelden",
    component: _53bfea86,
    name: "service-email-anmelden"
  }, {
    path: "/service/alltagshilfe-in-ihrer-stadt/:id?",
    component: _d673bb62,
    name: "service-alltagshilfe-in-ihrer-stadt-id"
  }, {
    path: "/service/alltagshilfe-telefonkontakt-plaene/:id?",
    component: _60e8e9f1,
    name: "service-alltagshilfe-telefonkontakt-plaene-id"
  }, {
    path: "/service/alltagshilfe-telefonkontakt/:id?",
    component: _18218dce,
    name: "service-alltagshilfe-telefonkontakt-id"
  }, {
    path: "/service/alltagshilfe/:id?",
    component: _88fbf582,
    name: "service-alltagshilfe-id"
  }, {
    path: "/service/b2b-kooperation/:id?",
    component: _b33c7c42,
    name: "service-b2b-kooperation-id"
  }, {
    path: "/service/engagierte-alltagshilfe/:id?",
    component: _9d75389e,
    name: "service-engagierte-alltagshilfe-id"
  }, {
    path: "/service/haushaltshilfe-finanzierungstipps-tel/:id?",
    component: _7030c3c8,
    name: "service-haushaltshilfe-finanzierungstipps-tel-id"
  }, {
    path: "/service/partner/:id?",
    component: _25427d1f,
    name: "service-partner-id"
  }, {
    path: "/zones/:id?",
    component: _50165c78,
    name: "zones-id"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/*",
    component: _2efaf336,
    name: "all"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
