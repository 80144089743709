<template>
    <v-btn
        :id="id"
        :color="color"
        :href="href"
        :type="type"
        :block="block"
        :large="large"
        :small="small"
        :to="to"
        :flat="flat"
        :round="round"
        :outline="outline"
        :fab="fab"
        :icon="icon"
        :dark="dark"
        depressed
        :loading="loading"
        :disabled="disabled"
        @click="handleClick"
    >
        <SVGIcon
            v-if="iconLeft && iconName"
            :icon-name="iconName"
            :class="[iconClass, 'btn__icon']"
        />
        <span v-if="text">
            {{ text }}
        </span>
        <SVGIcon
            v-if="!iconLeft && iconName"
            :icon-name="iconName"
            :class="[iconClass, 'btn__icon']"
        />
        <slot></slot>
    </v-btn>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'

export default {
    name: 'CSButton',

    components: {
        SVGIcon,
    },

    props: {
        text: {
            type: String,
            required: false,
        },
        color: {
            type: String,
            required: false,
            default: 'primary',
        },
        id: {
            type: String,
        },
        type: {
            type: String,
            required: false,
            default: 'button',
        },
        depressed: {
            type: Boolean,
            required: false,
        },
        /**
         * @deprecated with @click
         */
        onClick: {
            type: Function,
            required: false,
            default: () => {
                return true
            },
        },
        block: {
            type: Boolean,
            required: false,
            default: false,
        },
        large: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        flat: {
            type: Boolean,
            required: false,
            default: false,
        },
        round: {
            type: Boolean,
            required: false,
            default: true,
        },
        outline: {
            type: Boolean,
            required: false,
        },
        fab: {
            type: Boolean,
            required: false,
        },
        icon: {
            type: Boolean,
            required: false,
        },
        to: {
            default: '',
        },
        href: {
            default: '',
        },
        loading: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        dark: {
            type: Boolean,
        },

        iconName: {
            type: String,
            default: '',
            required: false,
        },

        iconLeft: {
            type: Boolean,
            required: false,
            default: false,
        },

        iconClass: {
            type: [String, Array],
            default: '',
            required: false,
        },
    },

    methods: {
        handleClick(e) {
            this.$emit('click', e)

            if (this.onClick) {
                this.onClick()
            }
        },
    },
}
</script>
