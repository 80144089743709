var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"nativeFooter",attrs:{"data-test":"footer"}},[_c('v-container',[_c('div',{staticClass:"nativeFooter__layout"},[_c('div',{staticClass:"nativeFooter__column",attrs:{"data-test":"footer-menu"}},[_c('ul',{staticClass:"nativeFooter__list"},_vm._l((_vm.menuLinks),function(item,key){return _c('li',{key:key},[_c('a',{class:[
                                    'nativeFooter__link',
                                    {
                                        'nativeFooter__link--space':
                                            item.space && _vm.nativePage,
                                    } ],attrs:{"href":item.link},on:{"click":function () {
                                        _vm.trackLink(item.text, item.link)
                                    }}},[_vm._v("\n                                "+_vm._s(item.text)+"\n                            ")])])}),0)]),_vm._v(" "),_c('div',{class:[
                        'nativeFooter__column--right',
                        { 'nativeFooter__column--right': !_vm.nativePage } ],attrs:{"data-test":"footer-companyDetails"}},[_c(_vm.leadGen ? 'div' : 'a',{tag:"component",class:[
                            'nativeFooter__logo',
                            {
                                'nativeFooter__logo--interactive': !_vm.leadGen,
                            } ],attrs:{"href":_vm.leadGen ? false : '/',"data-test":"footer-logo"},on:{"click":function () {
                                _vm.trackLink('Careship-logo', '/')
                            }}},[_c('SVGIcon',{staticClass:"icon__logo",attrs:{"icon-name":"careship-logo"}})],1),_vm._v(" "),_c('ul',[_c('li',[_c('a',{staticClass:"nativeFooter__link  nativeFooter__link--strong",attrs:{"href":("tel:" + (_vm.footer.contactInfo.phoneNumber))},on:{"click":function () {
                                        _vm.trackLink(
                                            'telephone',
                                            _vm.footer.contactInfo.phoneNumber
                                        )
                                    }}},[_vm._v("\n                                "+_vm._s(_vm.footer.contactInfo.phoneNumber)+"\n                            ")])]),_vm._v(" "),_c('li',[_c('ul',[_c('li',[_vm._v("\n                                    "+_vm._s(_vm.footer.contactInfo.workingHours
                                            .firstLine)+"\n                                ")]),_vm._v(" "),(
                                        _vm.footer.contactInfo.workingHours
                                            .secondLine
                                    )?_c('li',[_vm._v("\n                                    "+_vm._s(_vm.footer.contactInfo.workingHours
                                            .secondLine)+"\n                                ")]):_vm._e()])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"nativeFooter__link nativeFooter__link--strong",attrs:{"href":("mailto:" + (_vm.footer.contactInfo.email))},on:{"click":function () {
                                        _vm.trackLink(
                                            'email',
                                            _vm.footer.contactInfo.email
                                        )
                                    }}},[_vm._v("\n                                "+_vm._s(_vm.footer.contactInfo.email)+"\n                            ")])])]),_vm._v(" "),(_vm.nativePage)?_c('ul',{staticClass:"nativeFooter__list nativeFooter__social",attrs:{"data-test":"footer-socialMenu"}},_vm._l((_vm.menu.social),function(item,key){return _c('li',{key:key},[_c('a',{attrs:{"href":item.link},on:{"click":function () {
                                        _vm.trackLink(item.text, item.link)
                                    }}},[_c('SVGIcon',{staticClass:"icon__social",attrs:{"icon-name":item.icon}})],1)])}),0):_vm._e()],1)]),_vm._v(" "),(!_vm.leadGen)?_c('div',{attrs:{"data-test":"footer-citiesMenu"}},[_c('ul',{staticClass:"nativeFooter__cities"},_vm._l((_vm.orderedCities),function(item,key){return _c('li',{key:key,staticClass:"nativeFooter__linkContainer"},[(item.link)?_c('a',{staticClass:"nativeFooter__link",attrs:{"href":item.link},on:{"click":function () {
                                    _vm.trackLink(item.text, item.link)
                                }}},[_vm._v("\n                            "+_vm._s(item.text)+"\n                        ")]):_c('span',[_vm._v(_vm._s(item.text))])])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"nativeFooter__copyright"},[_vm._v("\n                ©"+_vm._s(new Date().getFullYear())+" Care Companion GmbH\n            ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }