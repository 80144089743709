import { fetchAccessToken } from '@/src/oauth'

const getAndSaveConfigToken = () => {
    const accessToken = localStorage.getItem('access_token')

    if (accessToken) {
        return accessToken
    }

    return fetchAccessToken()
        .then(response => {
            localStorage.setItem('access_token', response.data.access_token)
            return response.data.access_token
        })
        .catch(error => error)
}

export default getAndSaveConfigToken
