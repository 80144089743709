<template>
    <v-app>
        <div class="page page--basic">
            <main class="page__wrap">
                <nuxt />
            </main>
        </div>
    </v-app>
</template>

<script>
export default {
    name: 'BasicLayout',
}
</script>
