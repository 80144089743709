var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Toolbar',[_c('v-toolbar-title',[(_vm.isLeadGenVariation)?_c('div',{staticClass:"logoContainer"},[_c('Logo')],1):_c('router-link',{staticClass:"logoContainer",attrs:{"to":"/"}},[_c('Logo')],1)],1),_vm._v(" "),(_vm.isDefaultDashboardOrFunnelVariation)?_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","close-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',_vm._g({class:{
                    navItem: true,
                    'nuxt-link-active': _vm.isAboutUsNavItemActive,
                },attrs:{"aria-haspopup":"true"},on:{"mousedown":function($event){$event.preventDefault();}}},on),[_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_ABOUT_US')))]),_vm._v(" "),_c('SVGIcon',{staticClass:"navItem__icon",attrs:{"icon-name":"cs_chevron-down"}})],1)]}}],null,false,2963628578)},[_vm._v(" "),_c('div',{staticClass:"navItemMenu"},[_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/ueber-uns"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_ABOUT_CARESHIP'))+"\n            ")]),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/senioren-ratgeber"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_CARESHIP_MAGAZINE')))]),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/faq"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_FAQ_CUSTOMERS'))+"\n            ")]),_vm._v(" "),_c('router-link',{staticClass:"navItemMenu__item",attrs:{"to":"/partner"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_PARTNERS'))+"\n            ")]),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/newsroom"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_PRESS'))+"\n            ")]),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"https://mycareship.jobs.personio.de/"}},[_vm._v(_vm._s(_vm.$t('NAVBAR_CAREER'))+"\n            ")])],1)]):_vm._e(),_vm._v(" "),(_vm.isDefaultDashboardOrFunnelVariation)?_c('v-menu',{attrs:{"open-on-hover":"","bottom":"","offset-y":"","close-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('button',_vm._g({staticClass:"navItem",attrs:{"aria-haspopup":"true"},on:{"mousedown":function($event){$event.preventDefault();}}},on),[_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_CAREGIVERS')))]),_vm._v(" "),_c('SVGIcon',{staticClass:"navItem__icon",attrs:{"icon-name":"cs_chevron-down"}})],1)]}}],null,false,303334743)},[_vm._v(" "),_c('div',{staticClass:"navItemMenu"},[_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/unsere-betreuer"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_OUR_CAREGIVERS'))+"\n            ")]),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/hilfe"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_FAQ_CAREGIVERS'))+"\n            ")]),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"https://my.careship.de/login"}},[_vm._v("\n                "+_vm._s(_vm.$t('NAVBAR_MY_CARESHIP_LOGIN'))+"\n            ")])])]):_vm._e(),_vm._v(" "),(_vm.isDefaultDashboardOrFunnelVariation)?_c('a',{staticClass:"navItem",attrs:{"href":"/angebot"}},[_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_SERVICES')))])]):_vm._e(),_vm._v(" "),(_vm.isDefaultDashboardOrFunnelVariation)?_c('router-link',{staticClass:"navItem",attrs:{"to":"/preise"},nativeOn:{"mousedown":function($event){$event.preventDefault();}}},[_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_PRICE')))])]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.isDashboardVariation)?_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"navItemCta navItemCta--segmentedLeft",attrs:{"href":"/kunde/meine-alltagshelfer"}},[_vm._v("\n        "+_vm._s(_vm.$t('NAVBAR_LOGGED_IN_AREA'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isDashboardVariation)?_c('v-menu',{attrs:{"open-on-hover":"","left":"","offset-y":"","close-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('button',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"navItemCta navItemCta--segmentedRight",attrs:{"aria-haspopup":"true"},on:{"mousedown":function($event){$event.preventDefault();}}},on),[_c('SVGIcon',{staticClass:"navItem__icon",attrs:{"icon-name":"cs_chevron-down"}})],1)]}}],null,false,3486157569)},[_vm._v(" "),_c('div',{staticClass:"navItemMenu"},[_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/kunde/meine-alltagshelfer"}},[_c('SVGIcon',{staticClass:"navItemMenu__icon",attrs:{"icon-name":"cs_users"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_MY_CAREGIVERS')))])],1),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/kunde/meine-buchungen"}},[_c('SVGIcon',{staticClass:"navItemMenu__icon",attrs:{"icon-name":"cs_calendar"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_MY_BOOKINGS')))])],1),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/kunde/zahlungsmethode"}},[_c('SVGIcon',{staticClass:"navItemMenu__icon",attrs:{"icon-name":"cs_bank"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_MY_PAYMENT_METHOD')))])],1),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/kunde/meine-familie"}},[_c('SVGIcon',{staticClass:"navItemMenu__icon",attrs:{"icon-name":"cs_account"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_MY_PROFILE')))])],1),_vm._v(" "),_c('div',{staticClass:"navItemMenu__divider"}),_vm._v(" "),_c('a',{staticClass:"navItemMenu__item",attrs:{"href":"/kunde/logout"}},[_c('SVGIcon',{staticClass:"navItemMenu__icon",attrs:{"icon-name":"cs_log-out"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_LOGOUT')))])],1)])]):_vm._e(),_vm._v(" "),(
            _vm.isPhoneContactVariation ||
                _vm.isPhoneContactBVariation ||
                _vm.isLeadGenVariation
        )?_c('a',{class:{
            navItem: true,
            'navItem--dividerRight': _vm.isPhoneContactVariation,
        },attrs:{"href":("tel:" + _vm.phoneNumber)}},[_c('SVGIcon',{staticClass:"phoneIcon",attrs:{"icon-name":"phone-call"}}),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.phoneNumber))])],1):_vm._e(),_vm._v(" "),(_vm.isPhoneContactVariation)?_c('a',{staticClass:"navItem",attrs:{"href":_vm.applicationPortalLink}},[_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_BECOME_CAREGIVER')))])]):_vm._e(),_vm._v(" "),(_vm.isDefaultVariation)?_c('a',{staticClass:"navItem",attrs:{"href":"/kunde/login"}},[_c('span',[_vm._v(_vm._s(_vm.$t('NAVBAR_LOGIN')))])]):_vm._e(),_vm._v(" "),(_vm.isDefaultVariation)?_c('a',{staticClass:"navItem",attrs:{"href":_vm.applicationPortalLink}},[_vm._v("\n        "+_vm._s(_vm.$t('NAVBAR_BECOME_CAREGIVER'))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.isFunnelVariation)?_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"navItemCta",attrs:{"href":_vm.SRDraftUrl}},[_vm._v(_vm._s(_vm.$t('NAVBAR_CONTINUE_SEARCH')))]):_vm._e(),_vm._v(" "),(
            _vm.isDefaultVariation ||
                _vm.isPhoneContactVariation ||
                _vm.isPhoneContactBVariation
        )?_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"navItemCta",attrs:{"id":"button_header_booking-funnel","href":_vm.bookingPortalEntryLink}},[_vm._v(_vm._s(_vm.$t('NAVBAR_FIND_CAREGIVERS'))+"\n    ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }