<template>
    <div>
        <Toolbar>
            <v-toolbar-title>
                <div v-if="isLeadGenVariation" class="logoContainer">
                    <Logo />
                </div>
                <router-link
                    v-else
                    to="/"
                    class="logoContainer"
                    @click.native="toggleOverlay(false)"
                >
                    <Logo />
                </router-link>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <CSButton
                id="nav_phone_btn"
                :href="`tel:${phoneNumber}`"
                flat
                icon
                color="slate400"
                class="iconNavItem"
            >
                <SVGIcon icon-name="phone-call" class="menuIcon" />
            </CSButton>

            <CSButton
                v-if="!isLeadGenVariation"
                id="nav_toggle_btn"
                flat
                icon
                color="slate400"
                class="iconNavItem"
                @click="toggleOverlay(!displayOverlay)"
            >
                <SVGIcon
                    v-if="displayOverlay"
                    icon-name="cross"
                    class="menuIcon"
                />
                <SVGIcon v-else icon-name="menu" class="menuIcon" />
            </CSButton>
        </Toolbar>
        <div
            v-if="displayOverlay && isPhoneContactBVariation"
            class="overlay__transparency"
            @click="toggleOverlay(!displayOverlay)"
        ></div>
        <v-fade-transition mode="in-out">
            <div
                v-if="displayOverlay"
                :class="{
                    overlay: true,
                    'overlay--slim': isPhoneContactBVariation,
                }"
            >
                <div v-if="!isPhoneContactBVariation" class="navItems">
                    <div
                        v-if="isDefaultDashboardOrFunnelVariation"
                        :class="{ navItem__shadow: isAboutUsNavItemExpanded }"
                        :aria-expanded="String(isAboutUsNavItemExpanded)"
                    >
                        <button
                            :class="{
                                navItem: true,
                                'nuxt-link-active': isAboutUsNavItemActive,
                            }"
                            @click="toogleIsAboutUsNavItemExpanded"
                            @mousedown.prevent
                        >
                            <span class="navItem__title">{{
                                $t('NAVBAR_ABOUT_US')
                            }}</span>
                            <SVGIcon
                                class="navItem__icon"
                                :icon-name="
                                    isAboutUsNavItemExpanded
                                        ? 'cs_chevron-up'
                                        : 'cs_chevron-down'
                                "
                            />
                        </button>
                        <v-expand-transition mode="in-out">
                            <div v-if="isAboutUsNavItemExpanded">
                                <a href="/ueber-uns" class="navItem__item">{{
                                    $t('NAVBAR_ABOUT_CARESHIP')
                                }}</a>
                                <a
                                    href="/senioren-ratgeber"
                                    class="navItem__item"
                                >
                                    {{ $t('NAVBAR_CARESHIP_MAGAZINE') }}</a
                                >
                                <a href="/faq" class="navItem__item">
                                    {{ $t('NAVBAR_FAQ_CUSTOMERS') }}
                                </a>
                                <router-link
                                    to="/partner"
                                    class="navItem__item"
                                >
                                    {{ $t('NAVBAR_PARTNERS') }}
                                </router-link>
                                <a href="/newsroom" class="navItem__item">
                                    {{ $t('NAVBAR_PRESS') }}
                                </a>
                                <a
                                    href="https://mycareship.jobs.personio.de/"
                                    class="navItem__item"
                                >
                                    {{ $t('NAVBAR_CAREER') }}
                                </a>
                            </div>
                        </v-expand-transition>
                    </div>
                    <div
                        v-if="isDefaultDashboardOrFunnelVariation"
                        :class="{
                            navItem__shadow: isCaregiversNavItemExpanded,
                        }"
                        :aria-expanded="String(isCaregiversNavItemExpanded)"
                    >
                        <button
                            class="navItem"
                            @click="toogleCaregiversNavItemExpanded"
                            @mousedown.prevent
                        >
                            <span class="navItem__title">{{
                                $t('NAVBAR_CAREGIVERS')
                            }}</span>
                            <SVGIcon
                                class="navItem__icon"
                                :icon-name="
                                    isCaregiversNavItemExpanded
                                        ? 'cs_chevron-up'
                                        : 'cs_chevron-down'
                                "
                            />
                        </button>
                        <v-expand-transition mode="in-out">
                            <div v-if="isCaregiversNavItemExpanded">
                                <a
                                    href="/unsere-betreuer"
                                    class="navItem__item"
                                    >{{ $t('NAVBAR_OUR_CAREGIVERS') }}</a
                                >
                                <a href="/hilfe" class="navItem__item">{{
                                    $t('NAVBAR_FAQ_CAREGIVERS')
                                }}</a>
                                <a
                                    href="https://my.careship.de/login"
                                    class="navItem__item"
                                    >{{ $t('NAVBAR_MY_CARESHIP_LOGIN') }}</a
                                >
                            </div>
                        </v-expand-transition>
                    </div>
                    <a
                        v-if="isDefaultDashboardOrFunnelVariation"
                        href="/angebot"
                        class="navItem"
                    >
                        {{ $t('NAVBAR_SERVICES') }}
                    </a>
                    <router-link
                        v-if="isDefaultDashboardOrFunnelVariation"
                        to="/preise"
                        class="navItem"
                        @click.native="toggleOverlay(false)"
                        @mousedown.native.prevent
                    >
                        {{ $t('NAVBAR_PRICE') }}
                    </router-link>
                    <a
                        v-if="isPhoneContactVariation"
                        :href="applicationPortalLink"
                        class="navItem"
                    >
                        {{ $t('NAVBAR_BECOME_CAREGIVER') }}
                    </a>
                </div>
                <div class="footer">
                    <p v-if="isDashboardVariation" class="footer__hint">
                        {{ $t('NAVBAR_DASHBOARD_DESCRIPTION') }}
                    </p>
                    <p v-if="isPhoneContactBVariation" class="footer__hint">
                        {{ $t('NAVBAR_FIND_CAREGIVERS_HINT') }}
                    </p>
                    <a
                        v-if="isDashboardVariation"
                        v-ripple
                        href="/kunde/meine-alltagshelfer"
                        class="footer__cta"
                    >
                        {{ $t('NAVBAR_LOGGED_IN_AREA') }}
                    </a>
                    <a
                        v-if="isDashboardVariation"
                        href="/kunde/logout"
                        class="footer__cta footer__cta--link"
                    >
                        {{ $t('NAVBAR_LOGOUT') }}
                    </a>
                    <a
                        v-if="isFunnelVariation"
                        v-ripple
                        :href="SRDraftUrl"
                        class="footer__cta"
                    >
                        {{ $t('NAVBAR_CONTINUE_SEARCH') }}
                    </a>
                    <a
                        v-if="isDefaultVariation"
                        href="/kunde/login"
                        class="footer__cta footer__cta--link"
                    >
                        {{ $t('NAVBAR_LOGIN') }}
                    </a>
                    <a
                        v-if="isDefaultVariation"
                        :href="applicationPortalLink"
                        class="footer__cta footer__cta--link"
                    >
                        {{ $t('NAVBAR_BECOME_CAREGIVER') }}
                    </a>
                    <a
                        v-if="
                            isDefaultVariation ||
                                isPhoneContactVariation ||
                                isPhoneContactBVariation
                        "
                        v-ripple
                        :href="bookingPortalEntryLink"
                        class="footer__cta"
                    >
                        {{ $t('NAVBAR_FIND_CAREGIVERS') }}
                    </a>
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
import SVGIcon from '@/components/atoms/icons/SVGIcon.vue'
import CSButton from '@/components/atoms/button/CSButton.vue'
import Logo from './components/logo.vue'
import Toolbar from './components/toolbar.vue'

export default {
    name: 'Navigation',
    components: {
        SVGIcon,
        CSButton,
        Logo,
        Toolbar,
    },
    props: {
        navVariation: {
            required: true,
            type: String,
        },
        SRDraftUrl: {
            required: true,
            type: String,
        },
        phoneNumber: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            displayOverlay: false,
            isAboutUsNavItemExpanded: false,
            isCaregiversNavItemExpanded: false,
        }
    },
    computed: {
        bookingPortalEntryLink(){
            const queryString = window.location.search;

            // Construct the new URL with the existing query string
            return `kunde/anfrage/${queryString}`;
        },
        applicationPortalLink(){
            const queryString = window.location.search;

            // Construct the new URL with the existing query string
            return `/kunde/alltagshilfe/bewerbung/${queryString}`;
        },
        isDefaultVariation() {
            return this.navVariation === 'default'
        },
        isDashboardVariation() {
            return this.navVariation === 'dashboard'
        },
        isFunnelVariation() {
            return this.navVariation === 'funnel'
        },
        isPhoneContactVariation() {
            return this.navVariation === 'phone-contact'
        },
        isPhoneContactBVariation() {
            return this.navVariation === 'phone-contact-b'
        },
        isLeadGenVariation() {
            return this.navVariation === 'lead-gen'
        },
        isDefaultDashboardOrFunnelVariation() {
            return (
                this.isFunnelVariation ||
                this.isDashboardVariation ||
                this.isDefaultVariation
            )
        },
        isAboutUsNavItemActive() {
            return this.$route.path === '/partner'
        },
    },
    watch: {
        $route() {
            this.toggleOverlay(false)
        },
    },
    methods: {
        toggleOverlay(isOpen) {
            this.displayOverlay = isOpen
        },
        toogleIsAboutUsNavItemExpanded() {
            this.isAboutUsNavItemExpanded = !this.isAboutUsNavItemExpanded
            this.isCaregiversNavItemExpanded = false
        },
        toogleCaregiversNavItemExpanded() {
            this.isCaregiversNavItemExpanded = !this.isCaregiversNavItemExpanded
            this.isAboutUsNavItemExpanded = false
        },
    },
}
</script>

<style lang="stylus" scoped>
$navItemTransition = 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

.logoContainer {
    display: flex;
    color: $cs-primary;
}

.menuIcon {
    width: 100%;
    height: 20px;
}

.overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    padding-top: $cs-header-height;
    background-color: $cs-white;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--slim {
        height: auto;
    }
}

.overlay__transparency {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 98;
    background-color: #212121;
    opacity: 0.46;
}

.navItems {
    flex: 0 1 auto;
    overflow: auto;
    flex-direction: column;
    display: flex;
    padding: $cs-space 0;
}

.iconNavItem {
    height: 44px;
    width: 44px;
}

.navItem {
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid $cs-slate-100;
    padding: $cs-space;
    display: flex;
    font-size: 16px;
    text-decoration: none;
    color: $cs-slate-400;
    letter-spacing: 0px;
    line-height: 24px;
    text-align: left;

    &--expanded {
        background-color: $cs-white;

        .navItem__icon {
            transform: rotate(180deg);
        }
    }

    &:focus {
        outline: none;
        background-color: $cs-slate-050;
    }
}

// added by vue router automatically
.navItem.nuxt-link-active {
    border-left: 4px solid $cs-primary;
    padding-left: ($cs-space) - 4px;
}

.navItem__shadow {
    box-shadow: 0 0 $cs-space 0 rgba(0, 0, 0, 0.05);
}

.navItem__item {
    text-decoration: none;
    color: $cs-slate-400;
    width: 100%;
    padding: 12px 0 12px $cs-spaces.double;
    display: flex;
    font-size: 16px;
    background-color: $cs-white;
    letter-spacing: 0;
    line-height: 24px;

    &:first-child {
        padding-top: $cs-space;
    }

    &:last-child {
        padding-bottom: $cs-space;
        border-bottom: 1px solid $cs-slate-100;
    }

    &:focus {
        outline: none;
        background-color: $cs-slate-050;
    }
}

// added by vue router automatically
.navItem__item.nuxt-link-active {
    background-color: #fff7e5;
    font-weight: 500;
}

.navItem__title {
    flex: 1;
}

.navItem__icon {
    height: $cs-spaces.onehalf;
    width: $cs-spaces.onehalf;
}

.footer {
    background-color: $cs-white;
    padding: $cs-spaces.onehalf $cs-space;
    display: flex;
    flex-direction: column;
    flex: 0 1 auto;
}

.footer__cta {
    font-size: 16px;
    font-weight: 500;
    padding: $cs-spaces.half $cs-spaces.onehalf;
    background-color: $cs-primary;
    color: $cs-white;
    border-radius: 28px;
    text-decoration: none;
    transition: opacity $navItemTransition;
    height: 40px;
    text-align: center;
    margin-bottom: $cs-space;
    letter-spacing: 0px;
    line-height: 24px;

    &:hover {
        opacity: 0.9;
    }

    &:focus {
        outline: none;
        opacity: 0.8;
    }

    &--link {
        background-color: $cs-white;
        color: $cs-slate-400;
    }
}

.footer__hint {
    align-self: center;
    font-size: 14px;
    font-weight: 500;
    color: $cs-slate-300;
    margin: 0;
    margin-bottom: $cs-space;
}
</style>
